import React, { FC } from 'react';
import { MetaData, Layout, SRWImage } from '../components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { PrismicLink } from '@prismicio/react';

type PageParams = {
  slices: any;
  menu: any;
  footer: any;
  data: MetaData;
};

const NotFound: FC<PageParams> = ({ menu, footer, data }) => {
  return (
    <Layout menu={menu} footer={footer} data={data}>
      <Box
        display="flex"
        alignItems="center"
        className="wide fullHeight dark"
        p={0}
      >
        <SRWImage
          image={{ url: '/rhino.jpg', alt: 'Silicon Rhino page not found' }}
          width="100vw"
          height="100vh"
          type="cover"
        />
        <Box
          p={4}
          mt="-10vh"
          maxWidth="45vw"
          color="white"
          zIndex="100"
          position="absolute"
        >
          <Typography variant="h3" marginBottom={2}>
            Page Not Found
          </Typography>
          <Typography variant="h6">
            Well this is upsetting. It appears you&apos;ve landed on a page that
            our Rhino in Chief, Ryan can&apos;t find. If you feel he should have
            found it, just drop him a line.
          </Typography>
          <Box mt="10vh">
            <PrismicLink href='/contact'>
              <Button variant="outlined" color="inherit">
                Contact us
              </Button>
            </PrismicLink>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default NotFound;
